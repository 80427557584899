.planCardContainer {
  background-color: white;
  border: 1px soild black;

  border-radius: 5px;
}

.planHeader {
  height: 120px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  /* border-bottom: 2px solid #3c4b64; */
  border-radius: 5px;
}
.planBody {
  background-color: white;

  height: 350px;
  overflow: auto;
}

/* width */
.planBody::-webkit-scrollbar {
  width: 3px;
  border-radius: 9px;
}

/* Track */
.planBody::-webkit-scrollbar-track {
  background: #f2f2f2;
  border-radius: 9px;
}

/* Handle */
.planBody::-webkit-scrollbar-thumb {
  background: #aaaaaa;
  border-radius: 9px;
}

/* Handle on hover */
.planBody::-webkit-scrollbar-thumb:hover {
  background: #0747b7;
}

.planName {
  color: #3c4b64;
  text-transform: capitalize;
  margin-bottom: 10px;
}

.recommendedPlan {
  position: absolute;
  top: 5px;
  right: 5px;
  background-color: #0747b7;
  margin: 0;
  padding: 3px 6px;
  border-radius: 3px;
  color: white;
  font-size: 12px;
  font-weight: bold;
}
