.cardHeader {
      background-color: #3c4b64;
      height: 50px;
      padding: 0px 25px;
      display: flex;
      align-items: center;
      justify-content: space-between; 
      
}

.cardHeaderTitle { 
    color:#FFF;
    font-size: 18px;
    padding:0;
    margin:0
}

