// Here you can add other styles
.column-title {
  padding: 4px;
  border-bottom: 1px solid lightgray;
  background-color: #5cb85c;
  color: white;
  vertical-align: middle;
  text-align: center;
}

.c-sidebar .c-sidebar-brand {
  color: white;
  background: white;
  border-right: yellow;
}

/* For pdf */
.react-pdf__Page {
  margin-top: 0px;
}

.react-pdf__Page__textContent {
  border: 0px solid darkgrey;
  /*box-shadow: 5px 5px 5px 1px #ccc;
	border-radius: 5px;*/
}

.react-pdf__Page__annotations.annotationLayer {
  padding: 20px;
}

.react-pdf__Page__canvas {
  margin: 0 auto;
}

/* For all pages */
.all-page-container {
  height: 100%;
  max-height: 500px;
  overflow: auto;
}

.tableHover :hover {
  color: white;
  background-color: blue;
}

.tableSelected {
  background-color: blue;
}

.legend {
  list-style: none;
  margin-left: 10px;
}

.legend li {
  float: left;
  margin-right: 15px;
}

.legend span {
  border: 1px solid #ccc;
  float: left;
  width: 10px;
  height: 12px;
  margin: 2px;
}

.legend {
  position: fixed;
  top: 62%;
  left: 10%;
  /* bring your own prefixes */
  transform: translate(-50%, -50%);
}

/* your colors */
.legend .greendot {
  background-color: #00ff00;
}

.legend .yellowdot {
  background-color: #ffff33;
}

.legend .reddot {
  background-color: #ff3333;
}

.legend .blackdot {
  background-color: #000000;
}

.form-input-fail {
  border: 1px solid red;
}

.modal-header {
  color: #fff;
  padding: 9px 15px;
  border-bottom: 1px solid #eee;
  background-color: #3c4b64;
  -webkit-border-top-left-radius: 5px;
  -webkit-border-top-right-radius: 5px;
  -moz-border-radius-topleft: 5px;
  -moz-border-radius-topright: 5px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.cardhead {
  background-color: "#f87979";
  color: "white";
}

.tableHover :hover {
  color: white;
  background-color: blue;
}

.tableSelected {
  background-color: blue;
}

.hidden {
  display: none;
}

#circle {
  background: #e55353;
  width: 30px;
  height: 20px;
  // border-radius: 50%;
}

.limiter {
  width: 100%;
  margin: 0 auto;
}

.container-login100 {
  width: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  flex-wrap: wrap;
}

.wrap-login100 {
  width: 520px;
  min-height: 100vh;
  background: #fff;
  border-radius: 2px;
  position: relative;
}

/*------------------------------------------------------------------
[ Login100 more ]*/
.login100-more {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  width: calc(100% - 520px);
  position: relative;
  z-index: 1;
}

.login100-more h1 {
  color: #ffff;
  font-weight: 600;
  text-align: center;
  font-size: 3em;
  margin-top: 30%;
}

.login100-more::before {
  content: "";
  display: block;
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: #1b2569;
  background-image: url("../assets/logo1.png");
  background-repeat: no-repeat;
  background-size: 80%;
  background-position: center;
  background: -webkit-linear-gradient(bottom, #1b2569, #1b2569);
  background: -o-linear-gradient(bottom, #1b2569, #1b2569);
  background: -moz-linear-gradient(bottom, #1b2569, #1b2569);
  background: linear-gradient(bottom, #1b2569, #1b2569);
  opacity: 0.8;
}

/*==================================================================
[ Form ]*/

.login100-form {
  width: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.login100-form-title {
  display: block;
  width: 100%;
  font-family: Poppins-Bold;
  font-size: 39px;
  color: #333333;
  line-height: 1.2;
  text-align: left;
}



/*------------------------------------------------------------------
[ Input ]*/

.wrap-input100 {
  width: 100%;
  position: relative;
  border-bottom: 2px solid #dbdbdb;
  margin-bottom: 45px;
}

.label-input100 {
  font-family: Poppins-SemiBold;
  font-size: 18px;
  color: #999999;
  line-height: 1.2;
  padding-left: 2px;
}

.input100 {
  display: block;
  width: 100%;
  height: 50px;
  background: transparent;
  font-family: Poppins-Regular;
  font-size: 22px;
  color: #555555;
  line-height: 1.2;
  padding: 0 2px;
}

.focus-input100 {
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  pointer-events: none;
}

.focus-input100::before {
  content: "";
  display: block;
  position: absolute;
  bottom: -2px;
  left: 0;
  width: 0;
  height: 2px;

  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  -moz-transition: all 0.4s;
  transition: all 0.4s;

  background: #d5007d;
  background: -webkit-linear-gradient(45deg, #d5007d, #e53935);
  background: -o-linear-gradient(45deg, #d5007d, #e53935);
  background: -moz-linear-gradient(45deg, #d5007d, #e53935);
  background: linear-gradient(45deg, #d5007d, #e53935);
}


.input100:focus+.focus-input100::before {
  width: 100%;
}

.has-val.input100+.focus-input100::before {
  width: 100%;
}


.custom-card-header {
   background-color: #3c4b64;
}

.custom-card-header-title {
  font-size: 17px;
  border: 0px solid red;
  margin: 0px;
  font-weight: 500;
  color: white;
}


@media (max-width: 992px) {
  .login100-more h1 {
    font-size: 2em;
  }
}

@media (max-width: 576px) {
  .wrap-login100 {
    padding-left: 15px;
    padding-right: 15px;
  }
}